var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane'),_c('a-card',{staticClass:"container"},[_c('a-row',[_c('a-col',{attrs:{"span":24}},[_c('a-form-model',{attrs:{"colon":false,"model":_vm.form,"layout":"inline"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.query.apply(null, arguments)}}},[_c('a-form-model-item',[_c('a-input',{staticStyle:{"width":"150px"},attrs:{"placeholder":"项目名称"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"问题类型"},model:{value:(_vm.form.type),callback:function ($$v) {_vm.$set(_vm.form, "type", $$v)},expression:"form.type"}},_vm._l((_vm.qualityProblemType),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-select',{staticStyle:{"width":"150px"},attrs:{"placeholder":"确认状态"},model:{value:(_vm.form.status),callback:function ($$v) {_vm.$set(_vm.form, "status", $$v)},expression:"form.status"}},_vm._l(([
                {
                  name: '已完成',
                  value: 1,
                },
                   {
                  name: '未完成',
                  value: 0,
                }
                ]),function(item){return _c('a-select-option',{key:item.value,attrs:{"value":item.value}},[_vm._v(_vm._s(item.name))])}),1)],1),_c('a-form-model-item',[_c('a-space',[_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.query}},[_vm._v("查询")]),_c('a-button',{on:{"click":_vm.reset}},[_vm._v("重置")])],1)],1)],1)],1)],1),_c('Padding'),_c('a-table',{attrs:{"bordered":"","data-source":_vm.list,"loading":_vm.loading,"pagination":{
        total: _vm.total,
        current: _vm.current,
        pageSize: _vm.pageSize,
        showTotal: (total) => `共 ${total} 条记录`,
      },"rowKey":"id"},on:{"change":_vm.onChange}},[_c('a-table-column',{attrs:{"title":"项目名称","data-index":"projectName"}}),_c('a-table-column',{attrs:{"title":"问题类型","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"qualityProblemType","dictValue":text.type}})]}}])}),_c('a-table-column',{attrs:{"title":"问题描述","data-index":"description"}}),_c('a-table-column',{attrs:{"title":"整改要求","data-index":"demand"}}),_c('a-table-column',{attrs:{"title":"整改措施","data-index":"measure"}}),_c('a-table-column',{attrs:{"title":"完成时间","data-index":"finishTime","align":"center"}}),_c('a-table-column',{attrs:{"title":"责任部门","data-index":"chargeDeptName","align":"center"}}),_c('a-table-column',{attrs:{"title":"整改情况确认","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [(text.confirmStatus === 1)?_c('a-badge',{attrs:{"status":"success","text":"已完成"}}):_vm._e(),(text.confirmStatus === 0)?_c('a-badge',{attrs:{"status":"error","text":"未完成"}}):_vm._e()]}}])}),_c('a-table-column',{attrs:{"title":"操作","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('a-space',[(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.$router.push(_vm.$route.path + '/edit?id=' + text.id)}}},[_vm._v("编辑")]):_vm._e(),(_vm.$getPermission(_vm.$route.path + '/edit'))?_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.openDetail(text)}}},[_vm._v("详情")]):_vm._e()])]}}])})],1)],1),_c('Detail',{attrs:{"visible":_vm.visible,"detail":_vm.detail},on:{"cancel":function($event){_vm.visible = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }