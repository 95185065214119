<template>
  <div>
    <a-modal title="详情" :visible="visible" :footer="null" @cancel="$emit('cancel')" width="800px">
      <div style="margin-left: -16px">
        <a-tabs default-active-key="1" tab-position="left" size="small">
          <a-tab-pane key="1" tab="基本信息">
            <a-descriptions bordered size="small" :column="1">
              <a-descriptions-item label="项目名称">{{ detail.projectName}}</a-descriptions-item>
              <a-descriptions-item label="问题类型">
                <DataDictFinder dictType="qualityProblemType" :dictValue="detail.type" />
              </a-descriptions-item>

              <a-descriptions-item label="问题描述">{{ detail.description }}</a-descriptions-item>
              <a-descriptions-item label="整改要求">{{ detail.demand }}</a-descriptions-item>
              <a-descriptions-item label="整改措施">{{ detail.measure }}</a-descriptions-item>

              <a-descriptions-item label="完成时间">{{ detail.finishTime}}</a-descriptions-item>

              <a-descriptions-item label="责任部门">{{ detail.chargeDeptName}}</a-descriptions-item>

              <a-descriptions-item label="整改情况确认">
                <a-badge v-if="detail.confirmStatus === 1" status="success" text="已完成" />
                <a-badge v-if="detail.confirmStatus === 0" status="error" text="未完成" />
              </a-descriptions-item>
            </a-descriptions>
          </a-tab-pane>
          <a-tab-pane key="2" tab="整改照片">
            <a-carousel arrows autoplay>
              <div
                slot="prevArrow"
                slot-scope="props"
                class="custom-slick-arrow"
                style="left: 10px;zIndex: 1"
              >
                <a-icon type="left-circle" :title="props.i" />
              </div>
              <div
                slot="nextArrow"
                slot-scope="props"
                class="custom-slick-arrow"
                style="right: 10px"
              >
                <a-icon type="right-circle" :title="props.i" />
              </div>

              <div v-for="item in images" :key="item.image" class="item">
                <img :src="item.image" alt style="width: 100%; display: block" />
                <div class="title">{{item.title}}</div>
              </div>
            </a-carousel>
          </a-tab-pane>
        </a-tabs>
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    detail: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    images() {
      const arr = [];
      if (
        typeof this.detail.problemPicture === "string" &&
        this.detail.problemPicture
      ) {
        arr.push(
          ...this.detail.problemPicture.split(",").map(item => {
            return {
              image: item,
              title: "问题照片"
            };
          })
        );
      }
      if (
        typeof this.detail.rectifyPicture === "string" &&
        this.detail.rectifyPicture
      ) {
        arr.push(
          ...this.detail.rectifyPicture.split(",").map(item => {
            return {
              image: item,
              title: "整改后照片"
            };
          })
        );
      }
      return arr;
    }
  }
};
</script>


<style lang="less" scoped>
.ant-carousel .slick-slide {
  text-align: center;
  height: 160px;
  line-height: 160px;
  overflow: hidden;
}

.ant-carousel .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  opacity: 0.8;
}
.ant-carousel .custom-slick-arrow:before {
  display: none;
}
.ant-carousel .custom-slick-arrow:hover {
  opacity: 0.5;
}

.ant-carousel .slick-slide h3 {
  color: #fff;
}

.item {
  position: relative;

  .title {
    background-color: rgba(0, 0, 0, 0.3);
    padding: 4px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
  }
}
</style>