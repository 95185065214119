<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="24">
          <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
            <a-form-model-item>
              <a-input v-model="form.name" placeholder="项目名称" style="width: 150px"></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.type" placeholder="问题类型" style="width: 150px">
                <a-select-option
                  v-for="item in qualityProblemType"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-select v-model="form.status" placeholder="确认状态" style="width: 150px">
                <a-select-option
                  v-for="item in [
                  {
                    name: '已完成',
                    value: 1,
                  },
                     {
                    name: '未完成',
                    value: 0,
                  }
                  ]"
                  :key="item.value"
                  :value="item.value"
                >{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey="id"
      >
        <a-table-column title="项目名称" data-index="projectName" />
        <a-table-column title="问题类型" align="center">
          <template slot-scope="text">
            <DataDictFinder dictType="qualityProblemType" :dictValue="text.type" />
          </template>
        </a-table-column>
        <a-table-column title="问题描述" data-index="description" />
        <a-table-column title="整改要求" data-index="demand" />
        <a-table-column title="整改措施" data-index="measure" />

        <a-table-column title="完成时间" data-index="finishTime" align="center" />

        <a-table-column title="责任部门" data-index="chargeDeptName" align="center" />

        <a-table-column title="整改情况确认" align="center">
          <template slot-scope="text">
            <a-badge v-if="text.confirmStatus === 1" status="success" text="已完成" />
            <a-badge v-if="text.confirmStatus === 0" status="error" text="未完成" />
          </template>
        </a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a-space>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="$router.push($route.path + '/edit?id=' + text.id)"
              >编辑</a>
              <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                @click.prevent="openDetail(text)"
              >详情</a>
              <!-- <a
                href="#"
                v-if="$getPermission($route.path + '/edit')"
                class="danger"
                @click.prevent="deleteText(text)"
              >删除</a>-->
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>

    <Detail :visible="visible" :detail="detail" @cancel="visible = false" />
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import request from "@/api/request";
import { mapGetters } from "vuex";

import Detail from "./detail.vue";

function fetchList(data) {
  return request({
    url: "/office-service/quality/inspect/problem/list",
    method: "post",
    data
  });
}

// function remove(data) {
//   return request({
//     url: "/office-service/quality/inspect/problem/delete",
//     method: "post",
//     data
//   });
// }

export default {
  mixins: [watermark],

  components: {
    Detail
  },

  data() {
    return {
      form: {},

      loading: false,
      list: [],
      total: 0,
      current: 1,
      pageSize: 10,

      visible: false,
      detail: {}
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    qualityProblemType() {
      return this.findDataDict("qualityProblemType");
    }
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      this.loading = true;
      fetchList({
        pageNum: this.current,
        pageSize: this.pageSize,
        ...this.form
      })
        .then(res => {
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.current = 1;
      this.form = {};
      this.getList();
    },

    openDetail(text) {
      this.detail = text;
      this.visible = true;
    }
    // deleteText(text) {
    //   const that = this;
    //   this.$confirm({
    //     title: "确认要删除吗？",
    //     onOk() {
    //       remove({
    //         id: text.id
    //       }).then(() => {
    //         that.getList();
    //       });
    //     }
    //   });
    // }
  }
};
</script>
